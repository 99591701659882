// tag
.c-parts-tag {
  @include category-bg;
  display: inline-block;
  font-size: 1.2rem;
  line-height: 1;
  min-width: 70px;
  vertical-align: middle;
  color: #fff;
  text-align: center;
  background-color: #000;
  @media (--large) {
    padding: 4px 5px;
  }
  @media (--mobile) {
    font-size: 0.9rem;
    padding: 2px 3px;
  }
  &.-is-l {
    @media (--large) {
      min-width: 120px;
    }
    @media (--mobile) {
      min-width: 75px;
    }
  }
}
