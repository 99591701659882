.c-bottom-contact {
  background-color: $color-red;
  @media (--large) {
    padding: 43px 0 45px;
    transition: opacity .3s ease;
    &:hover {
      opacity: 0.85;
    }
  }
  @media (--mobile) {
    padding: 15px 0 20px;
  }
  color: #fff;
  &__inner {
    @include inner;
  }
  &__content {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  &__ttl {
    text-align: center;
  }
  &__ttl-en {
    text-align: center;
    line-height: 1;
    @media (--large) {
      font-size: 5rem;
    }
    @media (--mobile) {
      font-size: 3rem;
    }
  }
  &__ttl-jp {
    @media (--large) {
      font-size: 1.6rem;
      margin-top: 4px;
    }
    @media (--mobile) {
      font-size: 1rem;
    }
  }
  &__dsc {
    text-align: center;
    position: relative;
    @media (--large) {
      margin-top: 30px;
      font-size: 1.8rem;
      display: inline-block;
    }
    @media (--mobile) {
      margin-top: 20px;
      font-size: 1.1rem;
    }
    &::before {
      content: "";
      display: block;
      background-image: url('../images/common/icon-btn-arr.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      right: 0;
      @media (--large) {
        width: 70px;
        height: 15px;
        bottom: 8px;
        transform: translateX(170%);
      }
      @media (--mobile) {
        width: 35px;
        height: 7px;
        bottom: 4px;
        transform: translateX(130%);
      }
    }
  }
}
