.l-footer {
  background-color: #fff;
  position: relative;
  color: #000;
  &::before {
    content: "";
    display: block;
    width: 100%;
    background-color: #646464;
    position: absolute;
    left: 0;
    top: 0;
    @media (--large) {
      height: 15px;
    }
    @media (--mobile) {
      height: 7px;
    }
  }
  @media (--large) {
    padding: 63px 70px 30px;
  }
  @media (--mobile) {
    padding: 20px 0 13px;
  }
  &__inner {
    @include inner;
  }
  &__content {
    display: flex;
    justify-content: center;
    font-size: 1.2rem;
    @media (--mobile) {
      justify-content: center;
      flex-wrap: wrap;
    }
  }
  &__col {
    &:first-of-type {
      margin-right: 110px;
      @media (--mobile) {
        margin-right: 0;
      }
    }
    @media (--mobile) {
      flex-basis: 100%;
      text-align: center;
    }
  }
  &__logo {
    @media (--large) {
      height: 66px;
    }
    @media (--mobile) {
      height: 44px;
      margin-bottom: 20px;
    }
  }
  &__detail {

  }
  &__detail-text {
    text-align: left;
    display: inline-block;
    font-size: 1.8rem;
    line-height: (24/18);
    @media (--mobile) {
      font-size: 1.4rem;
    }
  }
  &__cp {
    margin-top: 80px;
    text-align: center;
    @media (--mobile) {
      margin-top: 20px;
    }
  }
}
