// mv
.p-home-mv {
  width: 100%;
  position: relative;
  @media (--large) {
    height: 600px;
  }
  @media (--mobile) {
    height: 300px;
  }
  &__inner {
    @include inner();
    height: 100%;
  }
  &__content {
    position: relative;
    height: 100%;
  }
  &__slider {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  &__slider-item {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 0;
    &::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      background: $swiper-bg;
      top: 0;
      left: 0;
    }
  }
  &__slider-item-bg {
    display: none;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    &.-is-pc {
      @media (--large) {
        display: block;
      }
    }
    &.-is-sp {
      @media (--mobile) {
        display: block;
      }
    }
  }
  &__head {
    line-height: 1;
    color: #fff;
    margin-top: -5%;
    z-index: 1;
    @media (--large) {
      font-size: 9rem;
      margin-left: auto;
      margin-right: auto;
      max-width: 1130px;
      width: 100%;
    }
    @media (--mobile) {
      font-size: 4.4rem;
      padding-left: 5px;
      // margin-left: 2.5em;
    }
  }
  &__head-sub {
    color: #fff;
    display: block;
    @media (--large) {
      font-size: 1.66rem;
      padding-left: 0.5em;
      margin-top: 20px;
    }
    @media (--mobile) {
      font-size: 1.25rem;
      margin-top: 12px;
      padding-left: .7em;
    }
  }
  &__scroll {
    position: absolute;
    color: #fff;
    transform-origin: right top;
    transform: rotate(90deg);
    @media (--large) {
      color: #fff;
      font-weight: 900;
      font-size: 250;
      width: 142px;
      font-size: 1.2rem;
      right: 67px;
      bottom: 63px;
    }
    @media (--mobile) {
      font-size: 0.8rem;
      right: 0;
      width: 95px;
      bottom: 23px;
    }
    &::before {
      content: "";
      display: block;
      height: 2px;
      background-color: #fff;
      position: absolute;
      top: 50%;
      right: 0;
      animation: scroll-bar 2.5s ease-out infinite ;
      @media (--large) {
        width: 32px;
      }
      @media (--mobile) {
        width: 20px;
      }
    }
  }
}

// concept
.p-home-concept {
  background-color: $color-bg-gray;
  position: relative;
  z-index: 0;
  @media (--large) {
    height: 600px;
  }
  @media (--mobile) {
    height: 315px;
  }
  &__inner {
    @include inner();
    height: 100%;
    position: relative;
  }
  &__content {
    height: 100%;
  }
  &__deco {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  &__deco-item {
    position: absolute;
    left: 0;
    font-weight: 900;
    color: #fff;
    transition: transform .1s ease;
    @media (--large) {
      font-size: 30rem;
      bottom: 90px;
      left: 10%;
    }
    @media (--mobile) {
      font-size: 10rem;
      left: -10px;
      bottom: 0;
    }
  }
  &__box {
    position: absolute;
    right: 0;
    @media (--large) {
      display: flex;
      align-items: stretch;
      flex-direction: row-reverse;
      width: 850px;
      top: -60px;
    }
    @media (--mobile) {
      top: -28px;
      width: 275px;
    }
  }
  &__box-text {
    background-image: url('../images/home/concept-box-bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  &__box-text {
    @media (--large) {
      padding: 34px 20px 40px 40px;
      width: 700px;
    }
    @media (--mobile) {
      padding: 21px 20px 20px;
    }
  }
  &__box-head {
    line-height: 1;
    color: #fff;
    @media (--large) {
      font-size: 4rem;
    }
    @media (--mobile) {
      font-size: 1.8rem;
    }
    &-red {
      color: $text-red;
    }
  }
  &__box-dsc {
    color: #fff;
    @media (--large) {
      font-size: 1.4rem;
      margin-top: 35px;
      line-height: 1.8;
    }
    @media (--mobile) {
      font-size: 1rem;
      margin-top: 10px;
      line-height: 1.7;
    }
  }
  &__box-btn {
    color: #fff;
    background-color: #000;
    display: block;
    @media (--large) {
      width: 150px;
    }
    @media (--mobile) {
      height: 40px;
    }
  }
  &__box-btn-link {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    @media (--large) {
      justify-content: center;
    }
    @media (--mobile) {
      padding-left: 21px;
    }
  }
  &__box-btn-txt {
    display: inline-block;
    @media (--large) {
      font-size: 2.2rem;
      transform: rotate(90deg);
    }
    @media (--mobile) {
      font-size: 1.3rem;
    }
  }
}
// company
.p-home-company {
  &__head {
    background-color: $color-black;
    overflow: hidden;
  }
  &__head-ttl {
    color: #fff;
    line-height: 0.9;
    position: relative;
    @media (--large) {
      font-size: 10.8rem;
      top: -14px;
    }
    @media (--mobile) {
      top: -7px;
      font-size: 5.7rem;
    }
  }
  &__inner {
    @include inner();
    &.-is-wide {
      @media (--large) {
        max-width: 1400px;
      }
    }
  }
  &__content {
    position: relative;
    z-index: 10;
    @media (--large) {
      padding: 100px 0;
    }
    @media (--mobile) {
      padding: 25px 0;
    }
  }
  &__body {
    position: relative;
    background-color: $color-black;
  }
  &__content {
    position: relative;
    z-index: 10;
    @media (--large) {
      padding: 58px 0;
    }
    @media (--mobile) {
      padding: 25px 0;
    }
  }
  &__list {
    color: #fff;
    &-item {
      display: flex;
      &:not(:last-of-type) {
        margin-bottom: 0;
      }
    }
  }
}
// company-list
.c-company {
  &__list-ttl {
    flex-basis: 140px;
    flex-shrink: 0;
    @media (--mobile) {
      flex-basis: 100px;
    }
    &__text {
      font-size: 1.8rem;
      line-height: 2;
      @media (--mobile) {
        font-size: 1.4rem;
        line-height: 1.67;
      }
      &.ls_large {
        letter-spacing: 1em;
      }
    }
  }
  &__list-detail {
    &__text {
      font-size: 1.8rem;
      line-height: 2;
      @media (--mobile) {
        font-size: 1.4rem;
        line-height: 1.67;
      }
      &__link {
        transition: opacity .3s ease;
        &:hover {
          opacity: .7;
        }
      }
    }
  }
}
// business
.p-home-business {
  background-color: $color-bg-gray;
  &__head {
    background-color: $color-bg-red;
    overflow: hidden;
    @media (--large) {
      padding-bottom: 58px;
    }
    @media (--mobile) {
      padding-bottom: 25px;
    }
  }
  &__head-ttl {
    color: #fff;
    line-height: 0.9;
    position: relative;
    @media (--large) {
      font-size: 10.8rem;
      top: -14px;
    }
    @media (--mobile) {
      top: -7px;
      font-size: 5.7rem;
    }
  }
  &__inner {
    @include inner();
    &.-is-wide {
      @media (--large) {
        max-width: 1400px;
      }
    }
  }
  &__body {
    position: relative;
  }
  &__content {
    position: relative;
    z-index: 10;
    @media (--large) {
      padding: 100px 0;
    }
    @media (--mobile) {
      padding: 25px 0;
    }
  }
  &__deco {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 0;
  }
  &__deco-item {
    @media (--large) {
      position: absolute;
      z-index: -1;
      top: 0;
      left: 50%;
      transform-origin: left bottom;
      pointer-events: none;
      color: #fff;
      font-weight: 900;
      font-size: 40rem;
      font-weight: 900;
      line-height: 0.9;
      letter-spacing: 0;
      span {
        display: block;
        transition: .1s linear;
      }
      &.-is-1 {
        transform: translate(-360px,-100%) rotate(90deg);
      }
      &.-is-2 {
        transform: translateY(-100%) rotate(90deg);
      }
    }
  }
  &__list {
    @media (--large) {
      display: flex;
      flex-wrap: wrap;
    }
  }
  &__list-item {
      @media (--large) {
        width: 100%;
        &:not(:nth-of-type(-n + 1)) {
          margin-top: 120px;
        }
      }
      @media (--mobile) {
        &:not(:first-of-type) {
          margin-top: 14px;
        }
      }
  }
  &__banner {
    text-align: center;
    @media (--large) {
      margin-top: 50px;
    }
    @media (--mobile) {
      margin-top: 17.5px;
    }
  }
}

// news
.p-home-news {
  @media (--large) {
    padding: 160px 0 148px;
  }
  @media (--mobile) {
    padding: 50px 0 57px;
  }
  &__inner {
    @include inner();
    @media (--mobile) {
      margin-left: 0;
    }
  }
  &__content {
    display: flex;
  }
  &__btn {
    display: block;
    background-color: #000;
    @media (--large) {
      width: 170px;
    }
    @media (--mobile) {
      width: 50px;
      margin-right: 35px;
    }
  }
  &__btn-link {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__btn-text {
    @media (--large) {
      display: inline-block;
      color: #fff;
      transform: rotate(90deg);
      font-size: 2rem;
      letter-spacing: 0.1em;
    }
    @media (--mobile) {
      display: none;
    }
  }
  &__main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  &__ttl {
    line-height: 0.8;
    @media (--large) {
      font-size: 11rem;
    }
    @media (--mobile) {
      font-size: 5.5rem;
    }
  }
  &__list {
    @media (--large) {
      margin-top: 66px;
    }
    @media (--mobile) {
      margin-top: 35px;
    }
  }
  &__list-item {
    @media (--large) {
      padding: 22px 0;
    }
    @media (--mobile) {
      padding: 14px 0;
    }
    &:first-of-type {
      padding-top: 0;
    }
    &:last-of-type {
      padding-bottom: 0;
    }
    & + & {
      border-top: 1px solid #000;
    }
  }
  &__list-link {
    display: flex;
    font-size: 1.6rem;
    line-height: 1.6;
    @media (--mobile) {
      flex-wrap: wrap;
      align-items: center;
    }
  }
  &__list-time {
    @media (--large) {
      width: 120px;
    }
    @media (--mobile) {
      width: 77px;
      font-size: 1rem;
      line-height: 1;
      vertical-align: middle;
    }
  }
  &__list-tag {
    @media (--large) {
      width: 170px;
    }
    @media (--mobile) {
      flex: 1;
      line-height: 1;
    }
  }
  &__list-ttl {
    @media (--large) {
      width: 210px;
      transition: color .2s ease;
      @at-root .p-home-news__list-link:hover & {
        color: $color-red;
        text-decoration: underline;
      }
    }
    @media (--mobile) {
      display: block;
      width: 100%;
      font-size: 1rem;
      margin-top: 5px;
    }
  }
  &__topics {
    @media (--large) {
      width: 900px;
      margin-top: 18px;
    }
    @media (--mobile) {
      margin-top: 52px;
    }
  }
  &__topics-head {
    line-height: 1;
    letter-spacing: 0;
    @media (--large) {
      font-size: 4.8rem;
    }
    @media (--mobile) {
      font-size: 2.6rem;
    }
  }
  &__topics-body {
    @media (--large) {
      margin-top: 30px;
    }
    @media (--mobile) {
      margin-top: 17px;
    }
  }
  &__topics-row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    & + & {
      @media (--large) {
        margin-top: 25px;
      }
      @media (--mobile) {
        margin-top: 15px;
      }
    }
  }
  &__topics-item {
    @media (--large) {
      a {
        transition: opacity .2s ease;
        &:hover {
          opacity :.7;
        }
      }
    }
    @at-root .p-home-news__topics-row.-is-2col &{
      @media (--large) {
        width: calc(50% - 15px);
        &:nth-of-type(even) {
          margin-left: 30px;
        }
        &:not(:nth-of-type(-n + 2)) {
          margin-top: 20px;
        }
      }
      @media (--mobile) {
        width: 100%;
        &:not(:first-of-type) {
          margin-top: 15px;
        }
      }
    }
    @at-root .p-home-news__topics-row.-is-4col &{
      @media (--large) {
        width: calc(25% - (30px / 4));
        &:not(:nth-of-type(4n + 1)) {
          margin-left: 10px;
        }
        &:not(:nth-of-type(-n + 4)) {
          margin-top: 10px;
        }
      }
      @media (--mobile) {
        width: calc(50% - 5px);
        &:not(:nth-last-of-type(even)) {
          margin-left: 10px;
        }
        &:not(:nth-of-type(-n + 2)) {
          margin-top: 15px;
        }
      }
    }
  }
}


.p-home-works {
  background-color: #000;
  &__head {
    overflow: hidden;
    max-width: 1400px;
    margin: 0 auto;
  }
  &__head-item {
    line-height: 0.9;
    color: #fff;
    @media (--large) {
      font-size: 11rem;
      margin-top: -15px;
    }
    @media (--mobile) {
      font-size: 5.4rem;
      margin-top: -7px;
    }
  }
  &__inner {
    @include inner();
  }
  &__content {
    @media (--large) {
      padding-bottom: 85px;
      margin-top: 134px;
    }
    @media (--mobile) {
      margin-top: 42px;
      padding-bottom: 38px;
    }
  }
  &__list {
    @media (--large) {
      display: flex;
      flex-wrap: wrap;
    }
    @media (--mobile) {
      padding: 0 25px;
    }
  }
  &__item {
    @media (--large) {
      width: calc(33.333% - (72px / 3));
      &:not(:nth-of-type(3n + 1)) {
        margin-left: 36px;
      }
      &:not(:nth-of-type(-n + 3)) {
        margin-top: 60px;
      }
      a {
        img {
          transition: opacity .2s ease;
        }

        &:hover img {
          opacity: .8;
        }
      }
    }
    @media (--mobile) {
      & + & {
        margin-top: 20px;
      }
    }
  }
  &__item-thum {
    position: relative;
    overflow: hidden;
    &::before {
      content: "";
      display: block;
      width: 100%;
      padding-top: calc(460%/700*100);
    }
    img {
      position: absolute;
      width: auto;
      height: auto;
      min-width: 100%;
      min-height: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
  }
  &__item-ttl {
    color: #fff;
    @media (--large) {
      margin-top: 20px;
    }
    @media (--mobile) {
      margin-top: 5px;
    }
  }
  &__item-ttl-item {
    line-height: (20 / 16);
    font-weight: 700;
    margin-left: 10px;
    @media (--large) {
      font-size: 1.6rem;
      @at-root .p-home-works__item a:hover &{
        text-decoration: underline;
      }
    }
    @media (--mobile) {
      font-size: 1rem;
    }
  }
  &__btn {
    width: 100%;
    display: flex;
    justify-content: center;
    @media (--large) {
      margin-top: 110px;
    }
    @media (--mobile) {
      margin-top: 52px;
    }
  }
  &__btn-item {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (--large) {
      width: 310px;
      height: 66px;
      transition: background-color .2s ease;
      &:hover {
        background-color: $color-red;
      }
    }
    @media (--mobile) {
      width: 155px;
      height: 33px;
    }
  }
  &__btn-head {
    font-weight: 700;
    color: $color-red;
    font-weight: 700;
    @media (--large) {
      font-size: 3rem;
      transition: color .2s ease;
      @at-root .p-home-works__btn-item:hover & {
        color: #fff;
      }
    }
    @media (--mobile) {
      font-size: 1.4rem;
    }
  }
}
