/**
* Variables
*/

$color-red: #dc0012;
$color-orange: #ee9e0a;
$color-blue: #0096df;
$color-green: #85b91f;
$color-black: #000;
$color-gray-dark: #646464;

$color-bg-red: $color-red;
$color-bg-gray: #eee;
$color-bg-gray-dark: #dcdddd;

$swiper-bg: rgba(105, 105, 105, 0.4);
$text-red: #c50023;


/** extend */
@media (--large) {
  //リンク時はhover opacity: .5
  .ex-link {
    opacity: 1;
    transition: opacity 0.2s linear;
    &:hover {
      opacity: .5;
    }
  }
}
