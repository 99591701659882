.c-pagetop {
  background-image: url('../images/common/pagetop-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  &.-is-work {
    background-image: url('../images/common/pagetop-bg-work.jpg');
  }
  &.-is-company {
    background-image: url('../images/common/pagetop-bg-company.jpg');
  }
  &.-is-recruit {
    background-image: url('../images/common/pagetop-bg-recruit.jpg');
  }
  @media (--mobile) {
    padding: 30px 0;
  }
  &__inner {
    @include inner();
  }
  &__content {
    width: 100%;
    color: #fff;
    @media (--large) {
      height: 300px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  &__ttl {
    line-height: 1;
    @media (--large) {
      font-size: 6.5rem;
    }
    @media (--mobile) {
      font-size: 3.8rem;
    }
  }
  &__ttl-sub {
    display: block;
    font-weight: 400;
    @media (--large) {
      font-size: 2rem;
      margin-top: 20px;
    }
    @media (--mobile) {
      font-size: 1.25rem;
      margin-top: 10px;
    }
  }
  &__thum {
    @media (--large) {
      width: 627px;
    }
    @media (--mobile) {
      margin-top: 20px;
    }
    &.-is-s {
      @media (--large) {
        width: 500px;
      }
      @media (--mobile) {
        margin-top: 20px;
      }
    }
  }
}
