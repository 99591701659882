@charset "UTF-8";
/**
 * Custom media queries
 * @example
 * @media (--mobile) {
 *    styles for mobile viewport
 * }
 */
/**
 * for mobile viewoport
 */
@import url(normalize.css);
@import url(swiper/swiper.min.css);
@import url(swiper/components/effect-fade/effect-fade.min.css);
@custom-media --mobile (max-width: 767px);
/**
 * for tablet and Pc. large viewport
 */
@custom-media --large (min-width: 768px);
@custom-media --phone (min-width: 544px);
@custom-media --tablet (min-width: 768px);
@custom-media --desktop(min-width: 992px);
/** http://bourbon.io/docs/#timing-functions */
/** example @include transition(all 5s $ease-in-circ);*/
/**
transition timing-function:
ease - cubic-bezier(0.25, 0.1, 0.25, 1.0) を指定したのと同じ（開始と完了を滑らかにする）（初期値）
linear - cubic-bezier(0.0, 0.0, 1.0, 1.0) を指定したのと同じ（一定）
ease-in - cubic-bezier(0.42, 0, 1.0, 1.0) を指定したのと同じ（ゆっくり始まる）
ease-out - cubic-bezier(0, 0, 0.58, 1.0) を指定したのと同じ（ゆっくり終わる）
ease-in-out - cubic-bezier(0.42, 0, 0.58, 1.0) を指定したのと同じ（ゆっくり始まってゆっくり終わる）
*/
/**
 * text ellipsis for block multiline
 */
/*
 unsupport IE, Edge, Fx

@mixin text-ellipsis-block($rows) {
  display: box;
  box-orient: vertical;
  line-clamp: $rows;
  overflow: hidden;
}
*/
/**
 * element size
 */
/**
 * position layout
 */
/**
 * images
 */
/**
* Variables
*/
/** extend */
@media (--large) {
  .ex-link {
    opacity: 1;
    transition: opacity 0.2s linear; }
    .ex-link:hover {
      opacity: .5; } }

@keyframes scroll-bar {
  0% {
    transform: scaleX(0);
    transform-origin: left; }
  10% {
    transform: scaleX(0);
    transform-origin: left; }
  50% {
    transform: scaleX(1);
    transform-origin: left; }
  51% {
    transform: scaleX(1);
    transform-origin: right; }
  90% {
    transform: scaleX(0);
    transform-origin: right; }
  100% {
    transform: scaleX(0);
    transform-origin: right; } }

@keyframes easeOutElastic {
  0% {
    transform: translateX(0%); }
  16% {
    transform: translateX(-132.27%); }
  28% {
    transform: translateX(-86.88%); }
  44% {
    transform: translateX(-104.63%); }
  59% {
    transform: translateX(-98.36%); }
  73% {
    transform: translateX(-100.58%); }
  88% {
    transform: translateX(-99.8%); }
  100% {
    transform: translateX(-100%); } }

/* == @import "../node_modules/normalize.css/normalize.css"; */
[v-cloak] {
  display: none; }

html {
  font-size: 62.5%; }

body {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 1.6rem;
  line-height: 1.6;
  letter-spacing: 0;
  font-feature-settings: "palt"; }
  @media (--large) {
    body {
      min-width: 1200px; } }

a {
  color: inherit;
  text-decoration: none; }

*, *:before, *:after {
  box-sizing: border-box; }

h1, h2, h3, h4, h5, h6, div, dl, dt, dd, p {
  margin: 0;
  padding: 0; }

ul, ol, li {
  padding: 0;
  margin: 0; }

li {
  list-style: none; }

img {
  vertical-align: bottom;
  max-width: 100%; }

figure {
  margin: 0;
  padding: 0; }

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input[type="number"] {
  -moz-appearance: textfield; }

.l-main {
  line-height: 1.2; }

@media (--mobile) {
  html, body {
    width: 100%;
    height: 100%; }
  h1, h2, h3, h4, h5, h6, div, dl, dt, dd, p {
    margin: 0;
    padding: 0; }
  img {
    height: auto;
    max-height: 100%;
    max-width: 100%;
    width: auto; } }

.l-footer {
  background-color: #fff;
  position: relative;
  color: #000; }
  .l-footer::before {
    content: "";
    display: block;
    width: 100%;
    background-color: #646464;
    position: absolute;
    left: 0;
    top: 0; }
    @media (--large) {
      .l-footer::before {
        height: 15px; } }
    @media (--mobile) {
      .l-footer::before {
        height: 7px; } }
  @media (--large) {
    .l-footer {
      padding: 63px 70px 30px; } }
  @media (--mobile) {
    .l-footer {
      padding: 20px 0 13px; } }
  @media (--large) {
    .l-footer__inner {
      max-width: 1130px;
      margin-left: auto;
      margin-right: auto; } }
  @media (--mobile) {
    .l-footer__inner {
      margin-left: 2.5rem;
      margin-right: 2.5rem; }
      .l-footer__inner.-is-wide {
        margin-left: initial;
        margin-right: initial; } }
  .l-footer__content {
    display: flex;
    justify-content: center;
    font-size: 1.2rem; }
    @media (--mobile) {
      .l-footer__content {
        justify-content: center;
        flex-wrap: wrap; } }
  .l-footer__col:first-of-type {
    margin-right: 110px; }
    @media (--mobile) {
      .l-footer__col:first-of-type {
        margin-right: 0; } }
  @media (--mobile) {
    .l-footer__col {
      flex-basis: 100%;
      text-align: center; } }
  @media (--large) {
    .l-footer__logo {
      height: 66px; } }
  @media (--mobile) {
    .l-footer__logo {
      height: 44px;
      margin-bottom: 20px; } }
  .l-footer__detail-text {
    text-align: left;
    display: inline-block;
    font-size: 1.8rem;
    line-height: 1.33333; }
    @media (--mobile) {
      .l-footer__detail-text {
        font-size: 1.4rem; } }
  .l-footer__cp {
    margin-top: 80px;
    text-align: center; }
    @media (--mobile) {
      .l-footer__cp {
        margin-top: 20px; } }

.l-header {
  z-index: 3001;
  background-color: #fff;
  position: relative;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0; }
  @media (--large) {
    .l-header {
      padding: 25px 95px 25px 45px; } }
  @media (--mobile) {
    .l-header {
      height: 57px; } }
  @media (--mobile) {
    .l-header__inner {
      display: flex;
      align-items: stretch; } }
  .l-header__content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end; }
    @media (--mobile) {
      .l-header__content {
        padding: 13px 0 11px 25px;
        flex: 1; } }
  @media (--large) {
    .l-header__logo {
      height: 66px; } }
  @media (--mobile) {
    .l-header__logo {
      height: 33px; } }
  @media (--large) {
    .l-header__nav-list {
      display: flex; }
    .l-header__nav-item {
      font-size: 1.6rem;
      line-height: 1;
      font-weight: 700; }
      .l-header__nav-item + .l-header__nav-item {
        margin-left: 75px; } }
  @media (--large) {
    .l-header__nav-link {
      transition: color .2s ease; }
      .l-header__nav-link:hover {
        color: #dc0012; } }
  @media (--mobile) {
    .l-header__menu-btn {
      display: block;
      padding: 18px; }
    .l-header__menu-btn-bars {
      display: block;
      width: 25px;
      position: relative;
      height: 100%; }
    .l-header__menu-btn-bar {
      display: block;
      width: 100%;
      height: 2px;
      background-color: #000;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: transform .3s ease, opacity .3s ease; }
      .l-header__menu-btn-bar.-is-1 {
        transform: translate(-50%, -8px); }
        #js-header.js-menu-open .l-header__menu-btn-bar.-is-1 {
          transform: translate(-50%, 0) rotate(135deg); }
      .l-header__menu-btn-bar.-is-2 {
        transform: translate(-50%, 0); }
        #js-header.js-menu-open .l-header__menu-btn-bar.-is-2 {
          opacity: 0; }
      .l-header__menu-btn-bar.-is-3 {
        transform: translate(-50%, 8px); }
        #js-header.js-menu-open .l-header__menu-btn-bar.-is-3 {
          transform: translate(-50%, 0) rotate(-135deg); }
    .l-header__contact {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 56px;
      background-color: #dc0012;
      color: #fff;
      font-size: 1rem; } }
  @media (--mobile) {
    .l-header__menu {
      display: none;
      z-index: 3010;
      position: absolute;
      bottom: 0;
      transform: translateY(100%);
      width: 100%;
      color: #fff;
      font-size: 1.6rem;
      max-height: calc(100vh - 57px);
      overflow: scroll; }
    .l-header__menu-item {
      background-color: #000;
      border-top: 1px solid #646464;
      position: relative; }
      .l-header__menu-item a {
        display: block;
        padding: 9px 25px; }
      .l-header__menu-item .icon {
        display: block;
        position: absolute;
        top: 50%;
        right: 25px;
        transform-origin: center;
        transform: translateY(-50%) rotate(45deg);
        width: 10px;
        height: 10px;
        border-top: 1px solid #fff;
        border-right: 1px solid #fff; }
      .l-header__menu-item.-is-sub {
        background-color: #323232; }
      .l-header__menu-item:last-of-type {
        border-bottom: 1px solid #646464; }
      .l-header__menu-item.-is-bg-red {
        background-color: #dc0012; }
      .l-header__menu-item .icon.-is-acc {
        border: none;
        transform: translate(30%, -50%);
        width: 15px;
        height: 15px; }
        .l-header__menu-item .icon.-is-acc::before {
          content: "";
          display: block;
          width: 100%;
          height: 1px;
          background-color: #fff;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%); }
        .l-header__menu-item .icon.-is-acc::after {
          content: "";
          display: block;
          width: 100%;
          height: 1px;
          background-color: #fff;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(90deg);
          transition: transform .3s ease; }
      .l-header__menu-item.js-is-open .icon.-is-acc::after {
        transform: translate(-50%, -50%) rotate(0deg); }
    .l-header__menu-acc {
      display: none; } }

/**
 * main.scss
 */
@media (--large) {
  .l-main {
    padding-top: 116px; } }

@media (--mobile) {
  .l-main {
    padding-top: 57px; } }

.p-home-mv {
  width: 100%;
  position: relative; }
  @media (--large) {
    .p-home-mv {
      height: 600px; } }
  @media (--mobile) {
    .p-home-mv {
      height: 300px; } }
  .p-home-mv__inner {
    height: 100%; }
    @media (--large) {
      .p-home-mv__inner {
        max-width: 1130px;
        margin-left: auto;
        margin-right: auto; } }
    @media (--mobile) {
      .p-home-mv__inner {
        margin-left: 2.5rem;
        margin-right: 2.5rem; }
        .p-home-mv__inner.-is-wide {
          margin-left: initial;
          margin-right: initial; } }
  .p-home-mv__content {
    position: relative;
    height: 100%; }
  .p-home-mv__slider {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1; }
  .p-home-mv__slider-item {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 0; }
    .p-home-mv__slider-item::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      background: rgba(105, 105, 105, 0.4);
      top: 0;
      left: 0; }
  .p-home-mv__slider-item-bg {
    display: none;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
    @media (--large) {
      .p-home-mv__slider-item-bg.-is-pc {
        display: block; } }
    @media (--mobile) {
      .p-home-mv__slider-item-bg.-is-sp {
        display: block; } }
  .p-home-mv__head {
    line-height: 1;
    color: #fff;
    margin-top: -5%;
    z-index: 1; }
    @media (--large) {
      .p-home-mv__head {
        font-size: 9rem;
        margin-left: auto;
        margin-right: auto;
        max-width: 1130px;
        width: 100%; } }
    @media (--mobile) {
      .p-home-mv__head {
        font-size: 4.4rem;
        padding-left: 5px; } }
  .p-home-mv__head-sub {
    color: #fff;
    display: block; }
    @media (--large) {
      .p-home-mv__head-sub {
        font-size: 1.66rem;
        padding-left: 0.5em;
        margin-top: 20px; } }
    @media (--mobile) {
      .p-home-mv__head-sub {
        font-size: 1.25rem;
        margin-top: 12px;
        padding-left: .7em; } }
  .p-home-mv__scroll {
    position: absolute;
    color: #fff;
    transform-origin: right top;
    transform: rotate(90deg); }
    @media (--large) {
      .p-home-mv__scroll {
        color: #fff;
        font-weight: 900;
        font-size: 250;
        width: 142px;
        font-size: 1.2rem;
        right: 67px;
        bottom: 63px; } }
    @media (--mobile) {
      .p-home-mv__scroll {
        font-size: 0.8rem;
        right: 0;
        width: 95px;
        bottom: 23px; } }
    .p-home-mv__scroll::before {
      content: "";
      display: block;
      height: 2px;
      background-color: #fff;
      position: absolute;
      top: 50%;
      right: 0;
      animation: scroll-bar 2.5s ease-out infinite; }
      @media (--large) {
        .p-home-mv__scroll::before {
          width: 32px; } }
      @media (--mobile) {
        .p-home-mv__scroll::before {
          width: 20px; } }

.p-home-concept {
  background-color: #eee;
  position: relative;
  z-index: 0; }
  @media (--large) {
    .p-home-concept {
      height: 600px; } }
  @media (--mobile) {
    .p-home-concept {
      height: 315px; } }
  .p-home-concept__inner {
    height: 100%;
    position: relative; }
    @media (--large) {
      .p-home-concept__inner {
        max-width: 1130px;
        margin-left: auto;
        margin-right: auto; } }
    @media (--mobile) {
      .p-home-concept__inner {
        margin-left: 2.5rem;
        margin-right: 2.5rem; }
        .p-home-concept__inner.-is-wide {
          margin-left: initial;
          margin-right: initial; } }
  .p-home-concept__content {
    height: 100%; }
  .p-home-concept__deco {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    overflow: hidden; }
  .p-home-concept__deco-item {
    position: absolute;
    left: 0;
    font-weight: 900;
    color: #fff;
    transition: transform .1s ease; }
    @media (--large) {
      .p-home-concept__deco-item {
        font-size: 30rem;
        bottom: 90px;
        left: 10%; } }
    @media (--mobile) {
      .p-home-concept__deco-item {
        font-size: 10rem;
        left: -10px;
        bottom: 0; } }
  .p-home-concept__box {
    position: absolute;
    right: 0; }
    @media (--large) {
      .p-home-concept__box {
        display: flex;
        align-items: stretch;
        flex-direction: row-reverse;
        width: 850px;
        top: -60px; } }
    @media (--mobile) {
      .p-home-concept__box {
        top: -28px;
        width: 275px; } }
  .p-home-concept__box-text {
    background-image: url("../images/home/concept-box-bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; }
  @media (--large) {
    .p-home-concept__box-text {
      padding: 34px 20px 40px 40px;
      width: 700px; } }
  @media (--mobile) {
    .p-home-concept__box-text {
      padding: 21px 20px 20px; } }
  .p-home-concept__box-head {
    line-height: 1;
    color: #fff; }
    @media (--large) {
      .p-home-concept__box-head {
        font-size: 4rem; } }
    @media (--mobile) {
      .p-home-concept__box-head {
        font-size: 1.8rem; } }
    .p-home-concept__box-head-red {
      color: #c50023; }
  .p-home-concept__box-dsc {
    color: #fff; }
    @media (--large) {
      .p-home-concept__box-dsc {
        font-size: 1.4rem;
        margin-top: 35px;
        line-height: 1.8; } }
    @media (--mobile) {
      .p-home-concept__box-dsc {
        font-size: 1rem;
        margin-top: 10px;
        line-height: 1.7; } }
  .p-home-concept__box-btn {
    color: #fff;
    background-color: #000;
    display: block; }
    @media (--large) {
      .p-home-concept__box-btn {
        width: 150px; } }
    @media (--mobile) {
      .p-home-concept__box-btn {
        height: 40px; } }
  .p-home-concept__box-btn-link {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center; }
    @media (--large) {
      .p-home-concept__box-btn-link {
        justify-content: center; } }
    @media (--mobile) {
      .p-home-concept__box-btn-link {
        padding-left: 21px; } }
  .p-home-concept__box-btn-txt {
    display: inline-block; }
    @media (--large) {
      .p-home-concept__box-btn-txt {
        font-size: 2.2rem;
        transform: rotate(90deg); } }
    @media (--mobile) {
      .p-home-concept__box-btn-txt {
        font-size: 1.3rem; } }

.p-home-company__head {
  background-color: #000;
  overflow: hidden; }

.p-home-company__head-ttl {
  color: #fff;
  line-height: 0.9;
  position: relative; }
  @media (--large) {
    .p-home-company__head-ttl {
      font-size: 10.8rem;
      top: -14px; } }
  @media (--mobile) {
    .p-home-company__head-ttl {
      top: -7px;
      font-size: 5.7rem; } }

@media (--large) {
  .p-home-company__inner {
    max-width: 1130px;
    margin-left: auto;
    margin-right: auto; } }

@media (--mobile) {
  .p-home-company__inner {
    margin-left: 2.5rem;
    margin-right: 2.5rem; }
    .p-home-company__inner.-is-wide {
      margin-left: initial;
      margin-right: initial; } }

@media (--large) {
  .p-home-company__inner.-is-wide {
    max-width: 1400px; } }

.p-home-company__content {
  position: relative;
  z-index: 10; }
  @media (--large) {
    .p-home-company__content {
      padding: 100px 0; } }
  @media (--mobile) {
    .p-home-company__content {
      padding: 25px 0; } }

.p-home-company__body {
  position: relative;
  background-color: #000; }

.p-home-company__content {
  position: relative;
  z-index: 10; }
  @media (--large) {
    .p-home-company__content {
      padding: 58px 0; } }
  @media (--mobile) {
    .p-home-company__content {
      padding: 25px 0; } }

.p-home-company__list {
  color: #fff; }
  .p-home-company__list-item {
    display: flex; }
    .p-home-company__list-item:not(:last-of-type) {
      margin-bottom: 0; }

.c-company__list-ttl {
  flex-basis: 140px;
  flex-shrink: 0; }
  @media (--mobile) {
    .c-company__list-ttl {
      flex-basis: 100px; } }
  .c-company__list-ttl__text {
    font-size: 1.8rem;
    line-height: 2; }
    @media (--mobile) {
      .c-company__list-ttl__text {
        font-size: 1.4rem;
        line-height: 1.67; } }
    .c-company__list-ttl__text.ls_large {
      letter-spacing: 1em; }

.c-company__list-detail__text {
  font-size: 1.8rem;
  line-height: 2; }
  @media (--mobile) {
    .c-company__list-detail__text {
      font-size: 1.4rem;
      line-height: 1.67; } }
  .c-company__list-detail__text__link {
    transition: opacity .3s ease; }
    .c-company__list-detail__text__link:hover {
      opacity: .7; }

.p-home-business {
  background-color: #eee; }
  .p-home-business__head {
    background-color: #dc0012;
    overflow: hidden; }
    @media (--large) {
      .p-home-business__head {
        padding-bottom: 58px; } }
    @media (--mobile) {
      .p-home-business__head {
        padding-bottom: 25px; } }
  .p-home-business__head-ttl {
    color: #fff;
    line-height: 0.9;
    position: relative; }
    @media (--large) {
      .p-home-business__head-ttl {
        font-size: 10.8rem;
        top: -14px; } }
    @media (--mobile) {
      .p-home-business__head-ttl {
        top: -7px;
        font-size: 5.7rem; } }
  @media (--large) {
    .p-home-business__inner {
      max-width: 1130px;
      margin-left: auto;
      margin-right: auto; } }
  @media (--mobile) {
    .p-home-business__inner {
      margin-left: 2.5rem;
      margin-right: 2.5rem; }
      .p-home-business__inner.-is-wide {
        margin-left: initial;
        margin-right: initial; } }
  @media (--large) {
    .p-home-business__inner.-is-wide {
      max-width: 1400px; } }
  .p-home-business__body {
    position: relative; }
  .p-home-business__content {
    position: relative;
    z-index: 10; }
    @media (--large) {
      .p-home-business__content {
        padding: 100px 0; } }
    @media (--mobile) {
      .p-home-business__content {
        padding: 25px 0; } }
  .p-home-business__deco {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 0; }
  @media (--large) {
    .p-home-business__deco-item {
      position: absolute;
      z-index: -1;
      top: 0;
      left: 50%;
      transform-origin: left bottom;
      pointer-events: none;
      color: #fff;
      font-weight: 900;
      font-size: 40rem;
      font-weight: 900;
      line-height: 0.9;
      letter-spacing: 0; }
      .p-home-business__deco-item span {
        display: block;
        transition: .1s linear; }
      .p-home-business__deco-item.-is-1 {
        transform: translate(-360px, -100%) rotate(90deg); }
      .p-home-business__deco-item.-is-2 {
        transform: translateY(-100%) rotate(90deg); } }
  @media (--large) {
    .p-home-business__list {
      display: flex;
      flex-wrap: wrap; } }
  @media (--large) {
    .p-home-business__list-item {
      width: 100%; }
      .p-home-business__list-item:not(:nth-of-type(-n + 1)) {
        margin-top: 120px; } }
  @media (--mobile) {
    .p-home-business__list-item:not(:first-of-type) {
      margin-top: 14px; } }
  .p-home-business__banner {
    text-align: center; }
    @media (--large) {
      .p-home-business__banner {
        margin-top: 50px; } }
    @media (--mobile) {
      .p-home-business__banner {
        margin-top: 17.5px; } }

@media (--large) {
  .p-home-news {
    padding: 160px 0 148px; } }

@media (--mobile) {
  .p-home-news {
    padding: 50px 0 57px; } }

@media (--large) {
  .p-home-news__inner {
    max-width: 1130px;
    margin-left: auto;
    margin-right: auto; } }

@media (--mobile) {
  .p-home-news__inner {
    margin-left: 2.5rem;
    margin-right: 2.5rem; }
    .p-home-news__inner.-is-wide {
      margin-left: initial;
      margin-right: initial; } }

@media (--mobile) {
  .p-home-news__inner {
    margin-left: 0; } }

.p-home-news__content {
  display: flex; }

.p-home-news__btn {
  display: block;
  background-color: #000; }
  @media (--large) {
    .p-home-news__btn {
      width: 170px; } }
  @media (--mobile) {
    .p-home-news__btn {
      width: 50px;
      margin-right: 35px; } }

.p-home-news__btn-link {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@media (--large) {
  .p-home-news__btn-text {
    display: inline-block;
    color: #fff;
    transform: rotate(90deg);
    font-size: 2rem;
    letter-spacing: 0.1em; } }

@media (--mobile) {
  .p-home-news__btn-text {
    display: none; } }

.p-home-news__main {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end; }

.p-home-news__ttl {
  line-height: 0.8; }
  @media (--large) {
    .p-home-news__ttl {
      font-size: 11rem; } }
  @media (--mobile) {
    .p-home-news__ttl {
      font-size: 5.5rem; } }

@media (--large) {
  .p-home-news__list {
    margin-top: 66px; } }

@media (--mobile) {
  .p-home-news__list {
    margin-top: 35px; } }

@media (--large) {
  .p-home-news__list-item {
    padding: 22px 0; } }

@media (--mobile) {
  .p-home-news__list-item {
    padding: 14px 0; } }

.p-home-news__list-item:first-of-type {
  padding-top: 0; }

.p-home-news__list-item:last-of-type {
  padding-bottom: 0; }

.p-home-news__list-item + .p-home-news__list-item {
  border-top: 1px solid #000; }

.p-home-news__list-link {
  display: flex;
  font-size: 1.6rem;
  line-height: 1.6; }
  @media (--mobile) {
    .p-home-news__list-link {
      flex-wrap: wrap;
      align-items: center; } }

@media (--large) {
  .p-home-news__list-time {
    width: 120px; } }

@media (--mobile) {
  .p-home-news__list-time {
    width: 77px;
    font-size: 1rem;
    line-height: 1;
    vertical-align: middle; } }

@media (--large) {
  .p-home-news__list-tag {
    width: 170px; } }

@media (--mobile) {
  .p-home-news__list-tag {
    flex: 1;
    line-height: 1; } }

@media (--large) {
  .p-home-news__list-ttl {
    width: 210px;
    transition: color .2s ease; }
    .p-home-news__list-link:hover .p-home-news__list-ttl {
      color: #dc0012;
      text-decoration: underline; } }

@media (--mobile) {
  .p-home-news__list-ttl {
    display: block;
    width: 100%;
    font-size: 1rem;
    margin-top: 5px; } }

@media (--large) {
  .p-home-news__topics {
    width: 900px;
    margin-top: 18px; } }

@media (--mobile) {
  .p-home-news__topics {
    margin-top: 52px; } }

.p-home-news__topics-head {
  line-height: 1;
  letter-spacing: 0; }
  @media (--large) {
    .p-home-news__topics-head {
      font-size: 4.8rem; } }
  @media (--mobile) {
    .p-home-news__topics-head {
      font-size: 2.6rem; } }

@media (--large) {
  .p-home-news__topics-body {
    margin-top: 30px; } }

@media (--mobile) {
  .p-home-news__topics-body {
    margin-top: 17px; } }

.p-home-news__topics-row {
  display: flex;
  flex-wrap: wrap;
  width: 100%; }
  @media (--large) {
    .p-home-news__topics-row + .p-home-news__topics-row {
      margin-top: 25px; } }
  @media (--mobile) {
    .p-home-news__topics-row + .p-home-news__topics-row {
      margin-top: 15px; } }

@media (--large) {
  .p-home-news__topics-item a {
    transition: opacity .2s ease; }
    .p-home-news__topics-item a:hover {
      opacity: .7; } }

@media (--large) {
  .p-home-news__topics-row.-is-2col .p-home-news__topics-item {
    width: calc(50% - 15px); }
    .p-home-news__topics-row.-is-2col .p-home-news__topics-item:nth-of-type(even) {
      margin-left: 30px; }
    .p-home-news__topics-row.-is-2col .p-home-news__topics-item:not(:nth-of-type(-n + 2)) {
      margin-top: 20px; } }

@media (--mobile) {
  .p-home-news__topics-row.-is-2col .p-home-news__topics-item {
    width: 100%; }
    .p-home-news__topics-row.-is-2col .p-home-news__topics-item:not(:first-of-type) {
      margin-top: 15px; } }

@media (--large) {
  .p-home-news__topics-row.-is-4col .p-home-news__topics-item {
    width: calc(25% - (30px / 4)); }
    .p-home-news__topics-row.-is-4col .p-home-news__topics-item:not(:nth-of-type(4n + 1)) {
      margin-left: 10px; }
    .p-home-news__topics-row.-is-4col .p-home-news__topics-item:not(:nth-of-type(-n + 4)) {
      margin-top: 10px; } }

@media (--mobile) {
  .p-home-news__topics-row.-is-4col .p-home-news__topics-item {
    width: calc(50% - 5px); }
    .p-home-news__topics-row.-is-4col .p-home-news__topics-item:not(:nth-last-of-type(even)) {
      margin-left: 10px; }
    .p-home-news__topics-row.-is-4col .p-home-news__topics-item:not(:nth-of-type(-n + 2)) {
      margin-top: 15px; } }

.p-home-works {
  background-color: #000; }
  .p-home-works__head {
    overflow: hidden;
    max-width: 1400px;
    margin: 0 auto; }
  .p-home-works__head-item {
    line-height: 0.9;
    color: #fff; }
    @media (--large) {
      .p-home-works__head-item {
        font-size: 11rem;
        margin-top: -15px; } }
    @media (--mobile) {
      .p-home-works__head-item {
        font-size: 5.4rem;
        margin-top: -7px; } }
  @media (--large) {
    .p-home-works__inner {
      max-width: 1130px;
      margin-left: auto;
      margin-right: auto; } }
  @media (--mobile) {
    .p-home-works__inner {
      margin-left: 2.5rem;
      margin-right: 2.5rem; }
      .p-home-works__inner.-is-wide {
        margin-left: initial;
        margin-right: initial; } }
  @media (--large) {
    .p-home-works__content {
      padding-bottom: 85px;
      margin-top: 134px; } }
  @media (--mobile) {
    .p-home-works__content {
      margin-top: 42px;
      padding-bottom: 38px; } }
  @media (--large) {
    .p-home-works__list {
      display: flex;
      flex-wrap: wrap; } }
  @media (--mobile) {
    .p-home-works__list {
      padding: 0 25px; } }
  @media (--large) {
    .p-home-works__item {
      width: calc(33.333% - (72px / 3)); }
      .p-home-works__item:not(:nth-of-type(3n + 1)) {
        margin-left: 36px; }
      .p-home-works__item:not(:nth-of-type(-n + 3)) {
        margin-top: 60px; }
      .p-home-works__item a img {
        transition: opacity .2s ease; }
      .p-home-works__item a:hover img {
        opacity: .8; } }
  @media (--mobile) {
    .p-home-works__item + .p-home-works__item {
      margin-top: 20px; } }
  .p-home-works__item-thum {
    position: relative;
    overflow: hidden; }
    .p-home-works__item-thum::before {
      content: "";
      display: block;
      width: 100%;
      padding-top: calc(460%/700*100); }
    .p-home-works__item-thum img {
      position: absolute;
      width: auto;
      height: auto;
      min-width: 100%;
      min-height: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
  .p-home-works__item-ttl {
    color: #fff; }
    @media (--large) {
      .p-home-works__item-ttl {
        margin-top: 20px; } }
    @media (--mobile) {
      .p-home-works__item-ttl {
        margin-top: 5px; } }
  .p-home-works__item-ttl-item {
    line-height: 1.25;
    font-weight: 700;
    margin-left: 10px; }
    @media (--large) {
      .p-home-works__item-ttl-item {
        font-size: 1.6rem; }
        .p-home-works__item a:hover .p-home-works__item-ttl-item {
          text-decoration: underline; } }
    @media (--mobile) {
      .p-home-works__item-ttl-item {
        font-size: 1rem; } }
  .p-home-works__btn {
    width: 100%;
    display: flex;
    justify-content: center; }
    @media (--large) {
      .p-home-works__btn {
        margin-top: 110px; } }
    @media (--mobile) {
      .p-home-works__btn {
        margin-top: 52px; } }
  .p-home-works__btn-item {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media (--large) {
      .p-home-works__btn-item {
        width: 310px;
        height: 66px;
        transition: background-color .2s ease; }
        .p-home-works__btn-item:hover {
          background-color: #dc0012; } }
    @media (--mobile) {
      .p-home-works__btn-item {
        width: 155px;
        height: 33px; } }
  .p-home-works__btn-head {
    font-weight: 700;
    color: #dc0012;
    font-weight: 700; }
    @media (--large) {
      .p-home-works__btn-head {
        font-size: 3rem;
        transition: color .2s ease; }
        .p-home-works__btn-item:hover .p-home-works__btn-head {
          color: #fff; } }
    @media (--mobile) {
      .p-home-works__btn-head {
        font-size: 1.4rem; } }

.c-bottom-contact {
  background-color: #dc0012;
  color: #fff; }
  @media (--large) {
    .c-bottom-contact {
      padding: 43px 0 45px;
      transition: opacity .3s ease; }
      .c-bottom-contact:hover {
        opacity: 0.85; } }
  @media (--mobile) {
    .c-bottom-contact {
      padding: 15px 0 20px; } }
  @media (--large) {
    .c-bottom-contact__inner {
      max-width: 1130px;
      margin-left: auto;
      margin-right: auto; } }
  @media (--mobile) {
    .c-bottom-contact__inner {
      margin-left: 2.5rem;
      margin-right: 2.5rem; }
      .c-bottom-contact__inner.-is-wide {
        margin-left: initial;
        margin-right: initial; } }
  .c-bottom-contact__content {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column; }
  .c-bottom-contact__ttl {
    text-align: center; }
  .c-bottom-contact__ttl-en {
    text-align: center;
    line-height: 1; }
    @media (--large) {
      .c-bottom-contact__ttl-en {
        font-size: 5rem; } }
    @media (--mobile) {
      .c-bottom-contact__ttl-en {
        font-size: 3rem; } }
  @media (--large) {
    .c-bottom-contact__ttl-jp {
      font-size: 1.6rem;
      margin-top: 4px; } }
  @media (--mobile) {
    .c-bottom-contact__ttl-jp {
      font-size: 1rem; } }
  .c-bottom-contact__dsc {
    text-align: center;
    position: relative; }
    @media (--large) {
      .c-bottom-contact__dsc {
        margin-top: 30px;
        font-size: 1.8rem;
        display: inline-block; } }
    @media (--mobile) {
      .c-bottom-contact__dsc {
        margin-top: 20px;
        font-size: 1.1rem; } }
    .c-bottom-contact__dsc::before {
      content: "";
      display: block;
      background-image: url("../images/common/icon-btn-arr.png");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      right: 0; }
      @media (--large) {
        .c-bottom-contact__dsc::before {
          width: 70px;
          height: 15px;
          bottom: 8px;
          transform: translateX(170%); } }
      @media (--mobile) {
        .c-bottom-contact__dsc::before {
          width: 35px;
          height: 7px;
          bottom: 4px;
          transform: translateX(130%); } }

.c-btn {
  background-color: #dc0012;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  vertical-align: bottom; }
  @media (--large) {
    .c-btn {
      height: 94px;
      font-size: 2.5rem;
      transition: opacity .3s ease; }
      .c-btn:hover {
        opacity: .8; } }
  @media (--mobile) {
    .c-btn {
      height: 45px;
      font-size: 2rem; } }
  .c-btn span {
    position: relative; }
    .c-btn span::after {
      content: "";
      display: block;
      width: 75px;
      height: 20px;
      background-image: url("../images/common/bottom-contact-arr.png");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      margin-left: 20px;
      position: absolute;
      bottom: 0;
      right: 0;
      transform: translateX(140%); }
      @media (--mobile) {
        .c-btn span::after {
          width: 35px;
          height: 10px; } }

.c-business-card {
  width: 100%;
  z-index: 0; }
  .c-business-card__img {
    position: relative;
    width: 55%;
    z-index: 0;
    overflow: hidden; }
    .c-business-card__img::before {
      content: "";
      display: block;
      width: 100%;
      padding-top: 66.666%; }
    .c-business-card__img img {
      min-width: 100%;
      min-height: 100%;
      max-width: initial;
      max-height: 100%;
      width: auto;
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
    @supports (object-fit: cover) {
      .c-business-card__img img {
        object-fit: cover;
        width: 100%;
        height: 100%; } }
  .c-business-card__text {
    position: relative;
    width: 55%;
    margin-left: auto;
    margin-top: -23%;
    z-index: 10; }
    @media (--large) {
      .c-business-card__text {
        padding: 25px; } }
    @media (--mobile) {
      .c-business-card__text {
        padding: 20px 10px 8px;
        margin-top: -22%;
        width: 70%; } }
    .c-business-card__text::before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: #fff;
      opacity: 0.7;
      z-index: -1; }
    .c-business-card__text::after {
      content: "";
      display: block;
      background-image: url("../images/common/business-card-deco.png");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0); }
      @media (--large) {
        .c-business-card__text::after {
          width: 56px;
          height: 28px;
          top: -14px;
          transition: transform 0.3s ease; } }
      @media (--mobile) {
        .c-business-card__text::after {
          width: 33.5px;
          height: 17px;
          top: -9px; } }
  .c-business-card__ttl {
    text-align: center;
    line-height: 1.15;
    font-weight: 700; }
    @media (--large) {
      .c-business-card__ttl {
        font-size: 2rem; } }
    @media (--mobile) {
      .c-business-card__ttl {
        font-size: 1.2rem; } }
  .c-business-card__dsc {
    color: #000; }
    @media (--large) {
      .c-business-card__dsc {
        font-size: 1.8rem;
        line-height: 1.33333;
        margin-top: 20px; } }
    @media (--mobile) {
      .c-business-card__dsc {
        font-size: 1rem;
        line-height: 1.28571;
        margin-top: 7px; } }
    .c-business-card__dsc__subttl {
      font-weight: bold; }
      @media (--large) {
        .c-business-card__dsc__subttl {
          font-size: 2rem;
          line-height: 1.4; } }
      @media (--mobile) {
        .c-business-card__dsc__subttl {
          font-size: 1.1rem;
          line-height: 1.25; } }
  .c-business-card figure {
    margin-bottom: 10.7%; }

.c-row {
  display: flex; }

.c-google-maps {
  position: relative;
  padding-bottom: 75%;
  height: 0;
  overflow: hidden; }

.c-google-maps iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important; }

.c-pagetop {
  background-image: url("../images/common/pagetop-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }
  .c-pagetop.-is-work {
    background-image: url("../images/common/pagetop-bg-work.jpg"); }
  .c-pagetop.-is-company {
    background-image: url("../images/common/pagetop-bg-company.jpg"); }
  .c-pagetop.-is-recruit {
    background-image: url("../images/common/pagetop-bg-recruit.jpg"); }
  @media (--mobile) {
    .c-pagetop {
      padding: 30px 0; } }
  @media (--large) {
    .c-pagetop__inner {
      max-width: 1130px;
      margin-left: auto;
      margin-right: auto; } }
  @media (--mobile) {
    .c-pagetop__inner {
      margin-left: 2.5rem;
      margin-right: 2.5rem; }
      .c-pagetop__inner.-is-wide {
        margin-left: initial;
        margin-right: initial; } }
  .c-pagetop__content {
    width: 100%;
    color: #fff; }
    @media (--large) {
      .c-pagetop__content {
        height: 300px;
        display: flex;
        align-items: center;
        justify-content: space-between; } }
  .c-pagetop__ttl {
    line-height: 1; }
    @media (--large) {
      .c-pagetop__ttl {
        font-size: 6.5rem; } }
    @media (--mobile) {
      .c-pagetop__ttl {
        font-size: 3.8rem; } }
  .c-pagetop__ttl-sub {
    display: block;
    font-weight: 400; }
    @media (--large) {
      .c-pagetop__ttl-sub {
        font-size: 2rem;
        margin-top: 20px; } }
    @media (--mobile) {
      .c-pagetop__ttl-sub {
        font-size: 1.25rem;
        margin-top: 10px; } }
  @media (--large) {
    .c-pagetop__thum {
      width: 627px; } }
  @media (--mobile) {
    .c-pagetop__thum {
      margin-top: 20px; } }
  @media (--large) {
    .c-pagetop__thum.-is-s {
      width: 500px; } }
  @media (--mobile) {
    .c-pagetop__thum.-is-s {
      margin-top: 20px; } }

.c-pagination {
  width: 100%;
  display: flex;
  margin-top: 5rem;
  justify-content: center;
  font-size: 13px; }
  .c-pagination li {
    display: block;
    width: 26px;
    height: 26px;
    margin: 0 7px; }
    .c-pagination li:not(.c-pagination__prev):not(.c-pagination__next) {
      border: 1px solid #333; }
  .c-pagination a {
    display: block;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center; }
    .c-pagination a:not([href]) {
      background-color: #333;
      color: #fff; }

.c-parts-tag {
  display: inline-block;
  font-size: 1.2rem;
  line-height: 1;
  min-width: 70px;
  vertical-align: middle;
  color: #fff;
  text-align: center;
  background-color: #000; }
  .c-parts-tag.-is-red {
    background-color: #dc0012; }
  .c-parts-tag.-is-blue {
    background-color: #0096df; }
  .c-parts-tag.-is-green {
    background-color: #85b91f; }
  .c-parts-tag.-is-black {
    background-color: #000; }
  .c-parts-tag.-is-gray {
    background-color: #646464; }
  .c-parts-tag.-is-orange {
    background-color: #ee9e0a; }
  @media (--large) {
    .c-parts-tag {
      padding: 4px 5px; } }
  @media (--mobile) {
    .c-parts-tag {
      font-size: 0.9rem;
      padding: 2px 3px; } }
  @media (--large) {
    .c-parts-tag.-is-l {
      min-width: 120px; } }
  @media (--mobile) {
    .c-parts-tag.-is-l {
      min-width: 75px; } }

.c-section {
  width: 100%; }
  @media (--large) {
    .c-section {
      display: flex; } }
  @media (--large) {
    .c-section__head {
      width: 40%; } }
  .c-section__head-ttl {
    line-height: 1.05; }
    @media (--large) {
      .c-section__head-ttl {
        font-size: 4.6rem; } }
    @media (--mobile) {
      .c-section__head-ttl {
        font-size: 3.8rem; } }
    .c-section__head-ttl span {
      color: #dc0012; }
  .c-section__head-sub {
    font-weight: 500; }
    @media (--large) {
      .c-section__head-sub {
        margin-top: 30px;
        font-size: 1.7rem; } }
    @media (--mobile) {
      .c-section__head-sub {
        margin-top: 10px;
        font-size: 1.2rem; } }
  @media (--large) {
    .c-section__body {
      flex: 1; } }
  @media (--mobile) {
    .c-section__body {
      margin-top: 30px; } }
  @media (--large) {
    .c-section__body-thum {
      width: calc(100% + (50vw - 565px));
      max-width: 880px;
      margin-bottom: 36px; } }
  @media (--mobile) {
    .c-section__body-thum {
      margin-bottom: 18px; } }
  .c-section__body-thum.-is-map {
    position: relative;
    margin-bottom: 0; }
    .c-section__body-thum.-is-map::before {
      content: "";
      display: block;
      width: 100%;
      padding-top: 56.8%; }
    .c-section__body-thum.-is-map iframe {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%; }
  .c-section__body-ttl {
    letter-spacing: 0.05em; }
    @media (--large) {
      .c-section__body-ttl {
        font-size: 4rem;
        line-height: 1.65; } }
    @media (--mobile) {
      .c-section__body-ttl {
        font-size: 2.4rem; } }
    @media (--large) {
      .c-section__body-ttl.-is-big {
        font-size: 4.9rem;
        line-height: 1.75; } }
    @media (--mobile) {
      .c-section__body-ttl.-is-big {
        font-size: 2.4rem;
        line-height: 1.6; } }
    .c-section__body-ttl span {
      color: #dc0012; }
  .c-section__body-ttl-sub {
    letter-spacing: 0.05em;
    line-height: 1.65;
    font-weight: 700;
    margin-top: 10px; }
    @media (--large) {
      .c-section__body-ttl-sub {
        font-size: 3rem; } }
    @media (--mobile) {
      .c-section__body-ttl-sub {
        font-size: 1.6rem;
        margin-top: 5px; } }
    .c-section__body-ttl-sub span {
      color: #dc0012; }
  .c-section__body-dsc {
    letter-spacing: 0.05em;
    line-height: 1.8; }
    .c-section__body-dsc li:not(:first-of-type) {
      margin-top: 1em; }
    @media (--large) {
      .c-section__body-dsc {
        margin-top: 15px;
        padding-left: 0.8em;
        font-size: 1.6rem; } }
    @media (--mobile) {
      .c-section__body-dsc {
        margin-top: 10px;
        font-size: 1.2rem;
        margin-top: 7px; } }
    @media (--large) {
      .c-section__body-dsc.-is-mt-l {
        margin-top: 32px; } }
    @media (--mobile) {
      .c-section__body-dsc.-is-mt-l {
        margin-top: 16px; } }
    .c-section__body-dsc.-is-pl-0 {
      padding-left: 0; }
  @media (--large) {
    .c-section__body-name {
      margin-top: 74px; } }
  @media (--mobile) {
    .c-section__body-name {
      margin-top: 30px; } }
  .c-section__body-name-en {
    font-weight: 700;
    letter-spacing: -0.05em; }
    @media (--large) {
      .c-section__body-name-en {
        font-size: 5rem; } }
    @media (--mobile) {
      .c-section__body-name-en {
        font-size: 2.6rem; } }
    .c-section__body-name-en span {
      color: #dc0012; }
  .c-section__body-name-jp {
    font-weight: 700;
    letter-spacing: 0; }
    @media (--large) {
      .c-section__body-name-jp {
        font-size: 2.2rem;
        margin-top: 33px; } }
    @media (--mobile) {
      .c-section__body-name-jp {
        font-size: 1.6rem;
        margin-top: 10px; } }
    .c-section__body-name-jp span {
      color: #dc0012; }

.c-section-dl {
  line-height: 1.9375; }
  @media (--large) {
    .c-section-dl {
      font-size: 1.6rem; }
      .c-section-dl a:hover {
        text-decoration: underline; } }
  @media (--mobile) {
    .c-section-dl {
      font-size: 1.2rem; } }
  .c-section-dl__row {
    display: flex;
    width: 100%; }
    .c-section-dl__row:not(:first-of-type) {
      border-top: 1px solid #000; }
      @media (--large) {
        .c-section-dl__row:not(:first-of-type) {
          padding-top: 23px; } }
      @media (--mobile) {
        .c-section-dl__row:not(:first-of-type) {
          padding-top: 12px; } }
    @media (--large) {
      .c-section-dl__row:not(:last-of-type) {
        padding-bottom: 26px; } }
    @media (--mobile) {
      .c-section-dl__row:not(:last-of-type) {
        padding-bottom: 13px; } }
  .c-section-dl__dt {
    font-weight: 700;
    color: #dc0012; }
    @media (--large) {
      .c-section-dl__dt {
        width: 140px; } }
    @media (--mobile) {
      .c-section-dl__dt {
        width: 90px; } }
  .c-section-dl__dd {
    flex: 1; }
  .c-section-dl__table-row {
    width: 100%; }
    @media (--large) {
      .c-section-dl__table-row {
        display: flex; } }
  @media (--large) {
    .c-section-dl__table-dt {
      width: 190px; } }
  @media (--large) {
    .c-section-dl__table-dd {
      flex: 1; } }
  @media (--large) {
    .c-section-dl__list {
      display: flex;
      flex-wrap: wrap; }
      .c-section-dl__list li {
        width: 40%; } }

.c-section-links {
  font-size: 1.5rem;
  line-height: 25px; }
  .c-section-links a {
    text-decoration: underline; }
    @media (--large) {
      .c-section-links a:hover {
        text-decoration: none; } }
  .c-section-links__item {
    position: relative; }
    @media (--large) {
      .c-section-links__item {
        padding-left: 23px; } }
    @media (--mobile) {
      .c-section-links__item {
        padding-left: 15px; } }
    .c-section-links__item::before {
      content: "";
      display: block;
      position: absolute;
      top: 3px;
      left: 0;
      width: 6px;
      height: 20px;
      background-color: #dc0012; }
    .c-section-links__item + .c-section-links__item {
      margin-top: 25px; }
      @media (--mobile) {
        .c-section-links__item + .c-section-links__item {
          margin-top: 15px; } }
  @media (--large) {
    .c-section-links__dd {
      padding-left: 10px; } }
  @media (--mobile) {
    .c-section-links__dd {
      padding-left: 5px; } }
  .c-section-links__dd li {
    display: inline-block;
    margin: 0 5px; }

/**
* wordpress
* WYSIWYG compatchble style
*/
.c-wp-post_article strong {
  font-weight: bold; }

.c-wp-post_article em {
  font-style: italic; }

.c-wp-post_article ul {
  margin-left: 1em;
  display: block;
  list-style-type: disc !important;
  margin-before: 1em;
  margin-after: 1em;
  margin-start: 0px;
  margin-end: 0px;
  padding-start: 40px; }

.c-wp-post_article ol {
  display: block;
  list-style-type: decimal !important;
  margin-before: 1em;
  margin-after: 1em;
  margin-start: 0px;
  margin-end: 0px;
  padding-start: 40px; }

.c-wp-post_article li {
  display: list-item;
  text-align: match-parent; }

.c-wp-post_article img {
  width: auto; }

.c-wp-post_article .alignleft {
  display: inline;
  float: left; }

.c-wp-post_article .alignright {
  display: inline;
  float: right; }

.c-wp-post_article .aligncenter {
  display: block;
  margin-right: auto;
  margin-left: auto; }

.c-wp-post_article blockquote.alignleft,
.c-wp-post_article .wp-caption.alignleft,
.c-wp-post_article img.alignleft {
  margin: 0.4em 1.6em 1.6em 0; }

.c-wp-post_article blockquote.alignright,
.c-wp-post_article .wp-caption.alignright,
.c-wp-post_article img.alignright {
  margin: 0.4em 0 1.6em 1.6em; }

.c-wp-post_article blockquote.aligncenter,
.c-wp-post_article .wp-caption.aligncenter,
.c-wp-post_article img.aligncenter {
  clear: both;
  margin-top: 0.4em;
  margin-bottom: 1.6em; }

.c-wp-post_article .wp-caption.alignleft,
.c-wp-post_article .wp-caption.alignright,
.c-wp-post_article .wp-caption.aligncenter {
  margin-bottom: 1.2em; }

.color-red {
  color: #dc0012; }

.font-bold {
  font-weight: bold; }

.u-fit-h {
  height: 100%; }

.u-fit-w {
  width: 100%; }

/**
 * clearfix micro
 */
.clearfix:after {
  content: "";
  clear: both;
  display: block; }

/**
 * text ellipsis., text...
 */
.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.u-hide {
  display: none; }

.u-click {
  cursor: pointer; }

@media (--large) {
  .for-mobile {
    display: none; } }

@media (--mobile) {
  .for-large {
    display: none; } }

/**
 * BrowserSync
 */
#__bs_notify__ {
  display: none !important;
  font-size: 8px !important;
  opacity: .25;
  max-height: 50px; }

.__debug_mihon__ {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  margin: 0 auto 0 auto;
  z-index: 100000;
  pointer-events: none;
  cursor: none;
  width: 100%;
  overflow: hidden;
  text-align: center;
  animation: __debug_mihon__ 1s infinite; }

.__debug_mihon__ {
  padding-top: 0px;
  margin-top: 0px; }

@-webkit-keyframes __debug_mihon__ {
  0% {
    opacity: 0; }
  60% {
    opacity: .5; }
  100% {
    opacity: .5; } }

/*
@example
 <div class="mihon">
  <img src="./images/sample1.png" alt="" class="type_pc">
  <img src="./images/sample2.png" alt="" class="type_sp">
</div>
*/
