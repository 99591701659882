.c-business-card {
  width: 100%;
  z-index: 0;
  &__img {
    position: relative;
    width: 55%;
    z-index: 0;
    overflow: hidden;
    &::before {
      content: "";
      display: block;
      width: 100%;
      padding-top: 66.666%;
    }
    img {
      min-width: 100%;
      min-height: 100%;
      max-width: initial;
      max-height: 100%;
      width: auto;
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);

    }
    @supports ( object-fit: cover ) {
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
  &__text {
    position: relative;
    width: 55%;
    margin-left: auto;
    margin-top: -23%;
    z-index: 10;
    @media (--large) {
      padding: 25px;
    }
    @media (--mobile) {
      padding: 20px 10px 8px;
      margin-top: -22%;
      width: 70%;
    }
    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: #fff;
      opacity: 0.7;
      z-index: -1;
    }
    &::after {
      content: "";
      display: block;
      background-image: url('../images/common/business-card-deco.png');
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%,0);
      @media (--large) {
        width: 56px;
        height: 28px;
        top: -14px;
        transition: transform 0.3s ease;
      }
      @media (--mobile) {
        width: 33.5px;
        height: 17px;
        top: -9px;
      }
    }
  }
  &__ttl {
    text-align: center;
    line-height: (23/20);
    font-weight: 700;
    @media (--large) {
      font-size: 2rem;
    }
    @media (--mobile) {
      font-size: 1.2rem;
    }
  }
  &__dsc {
    color: #000;
    @media (--large) {
      font-size: 1.8rem;
      line-height: (24/18);
      margin-top: 20px;
    }
    @media (--mobile) {
      font-size: 1rem;
      line-height: (18/14);
      margin-top: 7px;
    }
    &__subttl {
      font-weight: bold;
      @media (--large) {
        font-size: 2rem;
        line-height: (28/20);
      }
      @media (--mobile) {
        font-size: 1.1rem;
        line-height: (20/16);
      }
    }
  }
  figure {
    margin-bottom: 10.7%;
  }
}
