@keyframes scroll-bar {
  0% {
    transform: scaleX(0);
    transform-origin: left;
  }
  10% {
    transform: scaleX(0);
    transform-origin: left;
  }
  50% {
    transform: scaleX(1);
    transform-origin: left;
  }
  51% {
    transform: scaleX(1);
    transform-origin: right;
  }
  90% {
    transform: scaleX(0);
    transform-origin: right;
  }
  100% {
    transform: scaleX(0);
    transform-origin: right;
  }
}

@keyframes easeOutElastic {
	0% {
		transform: translateX(0%);
	}

	16% {
		transform: translateX(-132.27%);
	}

	28% {
		transform: translateX(-86.88%);
	}

	44% {
		transform: translateX(-104.63%);
	}

	59% {
		transform: translateX(-98.36%);
	}

	73% {
		transform: translateX(-100.58%);
	}

	88% {
		transform: translateX(-99.8%);
	}

	100% {
		transform: translateX(-100%);
	}

}
