
html {
  font-size: 62.5%; //1.0rem = 10px
}
body {
  font-family: $fontfamily;
  font-size: 1.6rem;
  line-height: 1.6;
  letter-spacing: 0;
  font-feature-settings: "palt";
  @media (--large) {
    min-width: 1200px;
  }
}
a {
  color: inherit;
  text-decoration: none;
}

*, *:before, *:after {
  box-sizing: border-box;
}

//text
h1, h2, h3, h4, h5 ,h6, div, dl, dt, dd, p {
  margin:0;
  padding: 0;
}

ul,ol,li {
  padding: 0;
  margin: 0;
}
li {
  list-style: none;
}

img {
  vertical-align: bottom;
  max-width: 100%;
}
figure{
  margin: 0;
  padding: 0;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"]{
  -moz-appearance:textfield;
}

.l-main {
  line-height: 1.2;
}

@media (--mobile) {
  html,body{
    width: 100%;
    height: 100%;
  }

  h1, h2, h3, h4, h5 ,h6, div, dl, dt,dd, p {
    margin:0;
    padding: 0;
  }

  img {
    height: auto;
    max-height: 100%;
    max-width: 100%;
    width: auto;
  }
}
