@charset "utf-8";

// Foundation
@import "foundation/setting/_font";
@import "foundation/setting/_mediaquery";
@import "foundation/setting/_timing";
@import "foundation/setting/_function";
@import "foundation/setting/_mixin";
@import "foundation/setting/_regulation";
@import "foundation/setting/_animation";

// vendor
@import "normalize.css"; /* == @import "../node_modules/normalize.css/normalize.css"; */
@import "swiper/swiper.min.css";
@import "swiper/components/effect-fade/effect-fade.min.css";



@import "foundation/base/_vue";
@import "foundation/base/_structure";

// Layout
@import "layout/_footer.scss";
@import "layout/_header.scss";
@import "layout/_main.scss";
@import "layout/_sidebar.scss";

//pages
@import "object/project/_home.scss";
@import "object/project/_single.scss";

// Object/Component
 
@import "object/component/_bottom-contact.scss";
@import "object/component/_bread.scss";
@import "object/component/_btn.scss";
@import "object/component/_business-card.scss";
@import "object/component/_flex.scss";
@import "object/component/_form.scss";
@import "object/component/_iframe.scss";
@import "object/component/_pagetop.scss";
@import "object/component/_pagination.scss";
@import "object/component/_parts.scss";
@import "object/component/_section.scss";
@import "object/component/_sns.scss";
@import "object/component/_wordpress.scss";

// Object/Component
@import "object/utility/_helper.scss";

// debug
@import "foundation/base/_debug";
