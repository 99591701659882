// 共通セクションパーツ
.c-section {
  width: 100%;
  @media (--large) {
    display: flex;
  }
  &__head {
    @media (--large) {
      width: 40%;
    }
  }
  &__head-ttl {
    line-height: 1.05;
    @media (--large) {
      font-size: 4.6rem;
    }
    @media (--mobile) {
      font-size: 3.8rem;
    }
    span {
      color: $color-red;
    }
  }
  &__head-sub {
    font-weight: 500;
    @media (--large) {
      margin-top: 30px;
      font-size: 1.7rem;
    }
    @media (--mobile) {
      margin-top: 10px;
      font-size: 1.2rem;
    }
  }
  &__body {
    @media (--large) {
      flex: 1;
    }
    @media (--mobile) {
      margin-top: 30px;
    }
  }
  &__body-thum {
    @media (--large) {
      width: calc(100% + (50vw - 565px));
      max-width: 880px;
      margin-bottom: 36px;
    }
    @media (--mobile) {
      margin-bottom: 18px;
    }
    &.-is-map {
      position: relative;
      margin-bottom: 0;
      &::before {
        content: "";
        display: block;
        width: 100%;
        padding-top: 56.8%;
      }
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }
    }
  }
  &__body-ttl {
    letter-spacing: 0.05em;
    @media (--large) {
      font-size: 4rem;
      line-height: 1.65;
    }
    @media (--mobile) {
      font-size: 2.4rem;
    }
    &.-is-big {
      @media (--large) {
        font-size: 4.9rem;
        line-height: 1.75;
      }
      @media (--mobile) {
        font-size: 2.4rem;
        line-height: 1.6;
      }
    }
    span {
      color: $color-red;
    }
  }
  &__body-ttl-sub {
    letter-spacing: 0.05em;
    line-height: 1.65;
    font-weight: 700;
    margin-top: 10px;
    @media (--large) {
      font-size: 3rem;

    }
    @media (--mobile) {
      font-size: 1.6rem;
      margin-top: 5px;
    }
    span {
      color: $color-red;
    }
  }
  &__body-dsc {
    letter-spacing: 0.05em;
    line-height: 1.8;
    li {
      &:not(:first-of-type) {
        margin-top: 1em;
      }
    }
    @media (--large) {
      margin-top: 15px;
      padding-left: 0.8em;
      font-size: 1.6rem;
    }
    @media (--mobile) {
      margin-top: 10px;
      font-size: 1.2rem;
      margin-top: 7px;
    }
    &.-is-mt-l {
      @media (--large) {
        margin-top: 32px;
      }
      @media (--mobile) {
        margin-top: 16px;
      }
    }
    &.-is-pl-0 {
      padding-left: 0;
    }
  }
  &__body-name {
    @media (--large) {
      margin-top: 74px;
    }
    @media (--mobile) {
      margin-top: 30px;
    }
  }
  &__body-name-en {
    font-weight: 700;
    letter-spacing: -0.05em;
    @media (--large) {
      font-size: 5rem;
    }
    @media (--mobile) {
      font-size: 2.6rem;
    }
    span {
      color: $color-red;
    }
  }
  &__body-name-jp {
    font-weight: 700;
    letter-spacing: 0;
    @media (--large) {
      font-size: 2.2rem;
      margin-top: 33px;
    }
    @media (--mobile) {
      font-size: 1.6rem;
      margin-top: 10px;
    }
    span {
      color: $color-red;
    }
  }
}


.c-section-dl {
  line-height: (31/16);
  @media (--large) {
    font-size: 1.6rem;
    a:hover {
      text-decoration: underline;
    }
  }
  @media (--mobile) {
    font-size: 1.2rem;
  }
  &__row {
    display: flex;
    width: 100%;
    &:not(:first-of-type) {
      border-top: 1px solid #000;
      @media (--large) {
        padding-top: 23px;
      }
      @media (--mobile) {
        padding-top: 12px;
      }
    }
    &:not(:last-of-type) {
      @media (--large) {
        padding-bottom: 26px;
      }
      @media (--mobile) {
        padding-bottom: 13px;
      }
    }
  }
  &__dt {
    font-weight: 700;
    color: $color-red;
    @media (--large) {
      width: 140px;
    }
    @media (--mobile) {
      width: 90px;
    }
  }
  &__dd {
    flex: 1;
  }
  &__table-row {
    @media (--large) {
      display: flex;
    }
    width: 100%;
  }
  &__table-dt {
    @media (--large) {
      width: 190px;
    }
  }
  &__table-dd {
    @media (--large) {
      flex: 1;
    }
  }
  &__list {
    @media (--large) {
      display: flex;
      flex-wrap: wrap;
      li {
        width: 40%;
      }
    }
  }
}

.c-section-links {
  font-size: 1.5rem;
  line-height: 25px;
  a {
    text-decoration: underline;
    @media (--large) {
      &:hover {
        text-decoration: none;
      }
    }
  }
  &__item {
    position: relative;
    @media (--large) {
      padding-left: 23px;
    }
    @media (--mobile) {
      padding-left: 15px;
    }
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 3px;
      left: 0;
      width: 6px;
      height: 20px;
      background-color: $color-red;
    }
    & + & {
      margin-top: 25px;
      @media (--mobile) {
        margin-top: 15px;
      }
    }
  }
  &__dd {
    @media (--large) {
      padding-left: 10px;
    }
    @media (--mobile) {
      padding-left: 5px;
    }
    li {
      display: inline-block;
      margin: 0 5px;
    }
  }
}
