.c-btn {
  background-color: $color-red;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  vertical-align: bottom;
  @media (--large) {
    height: 94px;
    font-size: 2.5rem;
    transition: opacity .3s ease;
    &:hover {
      opacity: .8;
    }
  }
  @media (--mobile) {
    height: 45px;
    font-size: 2rem;
  }
  span {
    position: relative;
    &::after {
      content: "";
      display: block;
      width: 75px;
      height: 20px;
      background-image: url('../images/common/bottom-contact-arr.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      margin-left: 20px;
      position: absolute;
      bottom: 0;
      right: 0;
      transform: translateX(140%);
      @media (--mobile) {
        width: 35px;
        height: 10px;
      }
    }
  }
}
