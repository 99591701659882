/**
 * Custom media queries
 * @example
 * @media (--mobile) {
 *    styles for mobile viewport
 * }
 */

/**
 * for mobile viewoport
 */
@custom-media --mobile (max-width: 767px);
/**
 * for tablet and Pc. large viewport
 */
@custom-media --large (min-width: 768px);

@custom-media --phone (min-width: 544px);
@custom-media --tablet (min-width: 768px);
@custom-media --desktop(min-width: 992px);
