/**
 * main.scss
 */

@media (--large) {
  .l-main {
    padding-top: 116px;
  }
}
//end @media (--large)

@media (--mobile) {
  .l-main {
    padding-top: 57px;
  }
}
//end @media (--mobile)
