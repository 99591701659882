.c-pagination {
  width: 100%;
  display: flex;
  margin-top: 5rem;
  justify-content: center;
  font-size: 13px;
  li {
    display: block;
    width: 26px;
    height: 26px;
    margin: 0 7px;
    &:not(.c-pagination__prev):not(.c-pagination__next){
      border: 1px solid #333;
    }
  }
  a {
    display: block;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    &:not([href]) {
      background-color: #333;
      color: #fff;
    }
  }
}
