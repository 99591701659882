.l-header {
  @include z-header(1);
  background-color: #fff;
  position: relative;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  @media (--large) {
    padding: 25px 95px 25px 45px;
  }
  @media (--mobile) {
    height: 57px;
  }
  &__inner {
    @media (--mobile) {
      display: flex;
      align-items: stretch;
    }
  }
  &__content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    @media (--mobile) {
      padding: 13px 0 11px 25px;
      flex: 1;
    }
  }
  &__logo {
    @media (--large) {
      height: 66px;
    }
    @media (--mobile) {
      height: 33px;
    }
  }
  @media (--large) {
    &__nav-list {
      display: flex;
    }
    &__nav-item {
      font-size: 1.6rem;
      line-height: 1;
      font-weight: 700;
      & + & {
        margin-left: 75px;
      }
    }
  }
  &__nav-link {
    @include text-hover;
  }
  @media (--mobile) {
    &__menu-btn {
      display: block;
      padding: 18px;
    }
    &__menu-btn-bars {
      display: block;
      width: 25px;
      position: relative;
      height: 100%;
    }
    &__menu-btn-bar {
      display: block;
      width: 100%;
      height: 2px;
      background-color: #000;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      transition:
        transform .3s ease,
        opacity .3s ease;
      &.-is-1 {
        transform: translate(-50%,-8px);
        @at-root #js-header.js-menu-open & {
          transform: translate(-50%,0) rotate(135deg);
        }
      }
      &.-is-2 {
        transform: translate(-50%,0);
        @at-root #js-header.js-menu-open & {
          opacity: 0;
        }
      }
      &.-is-3 {
        transform: translate(-50%,8px);
        @at-root #js-header.js-menu-open & {
          transform: translate(-50%,0) rotate(-135deg);
        }
      }
    }
    &__contact {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 56px;
      background-color: $color-red;
      color: #fff;
      font-size: 1rem;
    }
  }
  @media (--mobile) {
    &__menu {
      display: none;
      @include z-header(10);
      position: absolute;
      bottom: 0;
      transform: translateY(100%);
      width: 100%;
      color: #fff;
      font-size: 1.6rem;
      max-height: calc(100vh - 57px);
      overflow: scroll;
    }
    &__menu-item {
      background-color: #000;
      border-top: 1px solid #646464;
      position: relative;
      a {
        display: block;
        padding: 9px 25px;
      }
      .icon {
        display: block;
        position: absolute;
        top: 50%;
        right: 25px;
        transform-origin: center;
        transform: translateY(-50%) rotate(45deg);
        width: 10px;
        height: 10px;
        border-top: 1px solid #fff;
        border-right: 1px solid #fff;
      }
      &.-is-sub {
        background-color: #323232;
      }
      &:last-of-type {
        border-bottom: 1px solid #646464;
      }
      &.-is-bg-red {
        background-color: $color-red;
      }
      .icon.-is-acc {
        border: none;
        transform: translate(30%,-50%);
        width: 15px;
        height: 15px;
        &::before {
          content: "";
          display: block;
          width: 100%;
          height: 1px;
          background-color: #fff;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
        }
        &::after {
          content: "";
          display: block;
          width: 100%;
          height: 1px;
          background-color: #fff;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%) rotate(90deg);
          transition: transform .3s ease;
        }
      }
      &.js-is-open .icon.-is-acc::after{
        transform: translate(-50%,-50%) rotate(0deg);
      }
    }
    &__menu-acc {
      display: none;
    }
  }
}
